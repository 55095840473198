/* SamSam
/* created by on3stepThemes modified by 'G'


==================================================
Table of Content
==================================================
* color
* font
* general
* preloader
* mouse
* menu
* Galery
* resume
* blog
* contact
* to top
* footer
* new skills bar
* media all
* menu reveal
*/


@charset "utf-8";


/* Main Colors */
$white: #FFF;
$grey: #3A3A3A;
$medium_grey: #A5A5A5;
$light_grey: #E8E8E8;
$grey_transparent: #3A3A3A66;
$black: #171A1D;

$black_soft: #333;
$black_col: #2e333c;
$black_more: #0f0f0f;
$transparent_grey: #E6E6E6EF;
$transparent_black: #171A1D11;
$light_blue: #33CCCC;
$pink: #D3507A;


/* Primary Colors */
$primary: $grey;
// $secondary: #CC99FF;
$secondary: #262626;
$general: #727272;

$background_light: $light_grey;
$background_dark: $transparent_black;
$background_transparent: $transparent_grey;

/* Fonts */
// @import url('https://fonts.googleapis.com/css2?family=Italianno&family=Kaushan+Script&family=Montserrat&family=Recursive&family=Satisfy&family=Tangerine&family=Yellowtail&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

@font-face{
  font-family: "Recursive";
  src: local("Recursive"), url("./fonts/Recursive-Medium.ttf") format("truetype");
}
// @font-face{
//   font-family: "Montserrat";
//   src: local("Montserrat"), url("./fonts/Montserrat-Regular.ttf") format("truetype");
// }
// @font-face{
//   font-family: "Montserrat-bold";
//   src: local("Montserrat-bold"), url("./fonts/Montserrat-Bold.ttf") format("truetype");
// }
@font-face{
  font-family: "HateYourWritting";
  src: local("HateYourWritting"), url("./fonts/Regular.ttf") format("truetype");
}
$font-primary: "Montserrat", normal;
$font-secondary: "HateYourWritting", normal;
$font-thirdy: "Recursive", normal;

* {
    --body-font: $font-primary, Helvetica, $font-secondary;
    --title-font: $font-primary, Helvetica, $font-secondary;
    --primary-color-rgb: #D38EF0;
}

/*general*/
html {
  scroll-behavior: auto;
}
body{
  font-family: $font-primary;
  font-size: 16px;
  font-weight: 400;
  color: $grey;
  background: $background_light;
  line-height: 30px;
  padding: 0;
  line-height: 1.8em;
  word-spacing: 0px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.home{
  position:relative;
}
.col-white{
  color: $secondary;
}
.color{
  color: $secondary;
}
.transition{
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

h1, h2, h4, h3, h5, h6, .h1_big, .h1, .h2, .h3, .h4, .h5, .h6, footer.footer-light h5 {
  margin-top: 0;
  font-family: var(--title-font);
  font-weight: bold;
  color: $secondary;
}

.h1_big {
  color: $primary;
  position: relative;
  left: -3px;
  top: -10px;
  font-size: 64px;
  line-height: 70px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 34px;
  line-height: 42px;
}
.spacer-20 {
  width: 100%;
  height: 20px;
  display: block;
  clear: both;
}
.spacer-half {
  width: 100%;
  height: 15px;
  display: block;
  clear: both;
}

/* preloader */
#mainpreloader{
  .preloader{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 9999999;
    -webkit-animation-duration: 1.6s;
    animation-duration: 1.6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
     }
     @-webkit-keyframes fadeOut {
        0% {opacity: 1;}
        25% {opacity: 1;}
        75% {opacity: 1;}
        100% {opacity: 0;}
     }

     @keyframes fadeOut {
        0% {opacity: 1;}
        25% {opacity: 1;}
        75% {opacity: 1;}
        100% {opacity: 0;}
     }

     .fadeOut {
        -webkit-animation-name: fadeOut;
        animation-name: fadeOut;
     }
}
.mainpreloader {
  position: relative;
  animation: rotate 1s infinite linear;
  border: 3px solid rgba(207,31,31,.1);
  width: 100px;
  height: 100px;
  margin-right: -24px;
  border-radius: 999px;
}
.mainpreloader span {
  position: absolute;
  width: 100px;
  height: 100px;
  border: 4px solid transparent;
  border-top: 4px solid rgba(207,31,31,.7);
  top: -3px;
  left: -3px;
  border-radius: 999px;
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#root, .App{
  background: #000;
}
#routerhang{
  transform: unset !important;
  transform-origin: unset !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

section, .section{
  position: relative;
  // padding: 25px 0;
  width: 100%;
  height: auto;
  background: $background_light;
  background-size: cover;
  background-position-y: center;
  &.bg-bottom{
    &:before{
      position: absolute;
      bottom: 0px;
      left: 0;
      content: '';
      width: 100%;
      height: 50%;
      // background-image: linear-gradient(rgba(23,26,29,0), rgba(23,26,29,1));
      z-index: 1;
    }
  }
  &.bg-top{
    &:after{
      position: absolute;
      top: 0px;
      left: 0;
      content: '';
      width: 100%;
      height: 50%;
      // background-image: linear-gradient(rgba(23,26,29,1), rgba(23,26,29,0));
      z-index: 1;
    }
  }
  .react-parallax-bgimage {
    object-fit: cover;
    top: -150px;
  }
}
.py-5.position-relative{
  z-index: 2;
}
.spacer-single {
  width: 100%;
  height: 30px;
  display: block;
  clear: both;
}
.spacer-double {
  width: 100%;
  height: 60px;
  display: block;
  clear: both;
}
.v-center {
  min-height: 50vh;
  display: flex;
  align-items: center;
  padding: 5vh 0 0 0;
}
.list_location, .list_location li {
  list-style: none;
  padding: 0;
}
.list_location li {
  color: $primary;
  display: inline-block;
  margin-right: 35px;
  font-size: 14px;
  border-left: solid 1px rgba(0, 0, 0, .25);
  border-left-color: rgba(128, 128, 128, .25);
  padding-left: 10px;
  span {
    font-family: var(--title-font);
    color: $secondary;
    font-size: 16px;
    font-weight: bold;
    display: block;
  }
}

/*mouse*/
.mouse {
  position: absolute;
  width: 22px;
  height: 42px;
  bottom: 90px;
  left: 50%;
  margin-left: -12px;
  border-radius: 15px;
  cursor: pointer;
  border: 2px solid rgba(128, 128, 128, .25);
  -webkit-animation: intro 1s;
  animation: intro 1s;
  z-index: 2;
  &.hide{
    opacity: 0;
  }
  .scroll {
    display: block;
    width: 3px;
    height: 3px;
    margin: 6px auto;
    border-radius: 4px;
    background: $white;
    -webkit-animation: finger 1s infinite;
    animation: finger 1s infinite;
  }
}
@-webkit-keyframes finger {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
}

@keyframes finger {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
}
.space-border {
  width: 30px;
  height: 1px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 0px;
  margin-bottom: 40px;
  background: $secondary;
  &:before {
    width: 30px;
    height: 1px;
    content: "";
    position: absolute;
    left: -40px;
    background: rgba(128, 128, 128, .25);
  }
  &:after {
    width: 30px;
    height: 1px;
    content: "";
    position: absolute;
    right: -40px;
    background: rgba(128, 128, 128, .25);
  }
}

/*menu*/
.navbar-brand{
  cursor: pointer;
  z-index: 2;
  .imginit{
    display: block;
  }
  .imgsaly, .imggrey, .imgchef, .imgdesigner, .imglawyer, .imgdark{
    display: none;
  }
}

blockquote {
  font-family: $font-primary;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  border: none;
  border-left: solid 3px #333333;
  padding-left: 30px;
}
blockquote.q-big {
  color: $white;
  font-size: 32px;
  border: none;
  margin: 0;
  padding: 0;
}
blockquote.q-big .d-big {
  font-size: 150px;
  display: block;
  color: $secondary;
}
.d-quote-by {
  font-family: $font-primary;
  display: block;
  font-size: 14px;
  margin-top: 20px;
  border-left: solid 1px rgba(128, 128, 128, .2);
  padding-left: 20px;
  line-height: 1.5em;
}
.icon_quotations::before {
  content: "\7b";
}
.de_3d-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: rgba(34, 34, 34,.0);
  background-size: auto;
  position: relative;
  border-left: solid 1px rgba(128, 128, 128, .2);
  .d-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 40px;
  }
  i {
    position: absolute;
    font-size: 40px;
    top: 38px;
    left: 30px;
    color: $secondary;
  }
  h3 {
    margin-left: 50px;
    margin-top: 5px;
    margin-bottom: 25px;
  }
}

/*Galery*/
.my-gallery-class{
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  text-align: center;
}
.de_modal {
  cursor: pointer;
  .card-image-1 {
    overflow: hidden;
    transition: .5s;
    outline: none;
    img {
      transform: scale(1.05);
      // filter: grayscale(100%); // helps make the images in the gallery gray
      transition: .5s;
    }
    &:hover {
      z-index: 1;
      -webkit-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, .5);
      -moz-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, .5);
      box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, .5);
      transition: .5s;
      img {
        transform: scale(1);
        filter: grayscale(0);
        transition: .5s;
      }
    }
  }
}
.LightboxGal{
  position: fixed;
  width: 100%;
  height: 100%;
  background: $background_light;
  top: 0;
  left: 0;
  padding: 80px 0;
  z-index: 9999;
  overflow: auto;
    .closeGal{
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    .button-close {
      display: block;
      position: fixed;
      top: 40px;
      right: 40px;
      width: 48px;
      height: 48px;
      background: none;
      color: #808080;
      outline: none;
      border-radius: 30px;
      -moz-border-radius: 30px;
      -webkit-border-radius: 30px;
      border: 1px solid rgba(128, 128, 128, .5);
      transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 0;
        height: 1px;
        -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
        transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        border-left: 8px solid #808080808080;
        border-right: 8px solid #808080808080;
      }
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 0;
        height: 1px;
        -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
        transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        border-left: 8px solid #808080808080;
        border-right: 8px solid #808080808080;
      }
      &:hover{
        border-color: #808080808080;
        transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        &:before {
        border-left-width: 15px;
        border-right-width: 15px;
        }
        &:after {
          border-right-width: 15px;
          border-left-width: 15px;
        }
      }
    }
  }
  .mainLightbox{
    width: 100%;
    blockquote {
      position: relative;
      font-weight: 500;
      padding: 0;
      border: none;
      font-size: 22px;
      color: #808080;
      span {
        font-size: 14px;
        margin-top: 10px;
        display: block;
        padding-left: 25px;
        &:before {
          content: '';
          position: absolute;
          width: 20px;
          height: 1px;
          background: #808080;
          left: 0;
          margin-top: 14px;
        }
      }
    }
  }
}
.de_project-details {
  color: #aaaaaa;
  .d-field {
    padding: 5px 0;
    margin-bottom: 5px;
    border-bottom: solid 1px rgba(128, 128, 128, .1);
    i {
      color: #808080808080;
      margin-right: 10px;
    }
  }
  span {
    float: right;
    a{
      text-decoration: none;
      color: $secondary;
    }
  }
}
.de_project-info{
  h3{
      font-size: 24px;
      margin-bottom: 25px;
  }
  p{
    color: #aaa;
    margin-bottom: 20px;
  }
  .spacer-30 {
    width: 100%;
    height: 30px;
    display: block;
    clear: both;
  }
}

/*resume*/
h3.s_border {
  font-size: 28px;
  margin-bottom: 30px;
}
.d_timeline {
  position: relative;
  counter-reset: list 0;
  padding: 0;
  padding-left: 0px;
  padding-left: 22px;
}
.d_timeline-item {
  list-style: none;
  border-left: 1px solid rgba(128, 128, 128, .2);
  &:last-child{
    border-left: 1px solid rgba(128, 128, 128, 0);
  }
}
.d_timeline-title {
  color: #808080808080;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  background: linear-gradient(90deg, rgba(255,255,255,.1) 0%, rgba(255,255,255,0) 100%);
  &:before {
    color: #808080;
    position: relative;
    left: -22px;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    background-color: $secondary;
    counter-increment: list;
    content: counter(list)
  }
}
.d_timeline-text {
  margin: 0;
  padding: 0 0 40px 40px;
  .d_title {
    font-family: var(--title-font);
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 0px;
    display: block;
    font-size: 18px;
    color: #808080;
  }
  .d_company {
    margin-bottom: 10px;
    display: block;
    color: $secondary;
    font-size: 16px;
  }
}

/* blog */
.bloglist.item {
  margin-right: 25px;
}
.de_modal {
  cursor: pointer;
  img {
    display: block;
    width: 100%;
    transform: scale(1);
    // filter: grayscale(100); //this makes the image gray
    transition: .5s;
  }
}
.post-image {
  overflow: hidden;
  &:hover img {
    width: 100%;
    margin-bottom: 0px;
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    filter: grayscale(0%);
    transform: scale(1.1);
    transition: .5s;
    outline: none;
  }
}
.post-text {
  padding-top: 20px;
  h4 {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  p{
    color: #aaaaaa;
  }
}
.slick-dots li{
  margin: 0;
}
.slick-dots li button::before{
  font-size: 9px !important;
  color: rgba(128, 128, 128, .3) !important;
  opacity: 1;
}
.slick-dots li.slick-active button::before{
  font-size: 10px !important;
  color: $secondary;
}
.blog-read img {
  margin-bottom: 30px;
  &.img-fullwidth {
    width: 100%;
    height: auto;
  }
}
.post-like::before, .post-by::before, .post-comment::before {
  font-family: FontAwesome;
  padding-right: 10px;
  font-size: 11px;
}
.post-like::before {
  content: "\f08a";
}
.post-comment::before {
  content: "\f0e5";
}
.post-date, .post-comment, .post-like, .post-by, .post-author {
  margin-right: 20px;
  font-size: 13px;
  color: $white;
  font-family: $font-primary;
}
.post-text {
  padding-top: 0;
}
#blog-comment ol {
  padding-left: 40px;
  li {
    list-style: none;
    margin-left: -40px;
    padding-left: 0;
    min-height: 70px;
    line-height: 1.6em;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px #eee;
    border-bottom-color: rgba(128, 128, 128, .1);
    .avatar {
      position: absolute;
      margin-top: 5px;
      img{
        width: 80px;
        border-radius: 100%;
        -moz-border-radius: 100%;
        -webkit-border-radius: 100%;
      }
    }
    .comment-info {
      margin-left: 100px;
      margin-bottom: 5px;
       .c_name {
        display: block;
        font-weight: 700;
        color: #808080808080;
      }
      .c_date {
        font-size: 12px;
        color: $secondary;
      }
       .c_reply {
        padding-left: 20px;
        margin-left: 20px;
        border-left: solid 1px #ddd;
        border-left-color: rgba(128, 128, 128, .2);
        font-size: 12px;
        color: $secondary;
      }
    }
    .comment {
      margin-left: 100px;
    }
  }
}

/* to top */
#scroll-to-top {
    cursor: pointer;
    width: max-content;
    height: max-content;
    position: fixed;
    right: 10px;
    z-index: 999;
    &.init{
      bottom: -60px;
      transition: all 0.3s ease;
    }
    &.show{
      bottom: 15px;
      transition: all 0.3s ease;
    }
    div{
      font-size: .8rem;
      width: 35px;
      height: 35px;
      text-align: center;
      line-height: 30px;
      border-radius: 30px;
      border: solid 1px rgba(255,255,255,.2);
      background: $background_light;
      transition: all 0.3s ease;
      i{
        color: $white;
        &:before {
          font-family: "FontAwesome";
          font-size: 18px;
          content: "\f106";
          position: relative;
          top: 2px;
        }
      }
      &:hover{
        background: #1b1b1b;
        transform: scale(1.1);
        transition: all 0.3s ease;
      }
    }
}

/* footer */
// footer {
//   position: relative;
//   color: rgba(128, 128, 128, .8);
//   padding: 20px 0;
//   background: rgba(0, 0, 0, .1);
//   border-top: solid 1px rgba(128, 128, 128, .1);
//   .social-icons {
//     display: block;
//     float: right;
//   }
//    a {
//     color: rgba(128, 128, 128, .5);
//     text-decoration: none;
//       &:hover{
//         color: rgba(128, 128, 128, .5);
//       }
//     i {
//       font-size: 16px !important;
//       color: #808080808080;
//       text-shadow: none;
//       padding: 12px 10px 8px 10px;
//       width: 34px;
//       height: 34px;
//       text-align: center;
//       border-radius: 30px;
//       -moz-border-radius: 30px;
//       -webkit-border-radius: 30px;
//       margin: 0 3px 0 3px;
//       transition: .5s;
//       &:hover {
//         background: #808080;
//         border-color: #eceff3;
//         color: #333;
//         transition: .5s;
//       }
//       &:before{
//         position: relative;
//         top: -2px;
//       }
//     }
//   }
// }

/* new skills bar */
.d-skill{
  position: relative;
  margin-bottom: 20px;
}
.d-skills-bar .d-bar {
  margin: 25px 0;
}
.d-skills-bar .d-bar .d-info {
  margin-bottom: 8px;
  color: #222222;
}
.d-skills-bar .d-bar .d-info{
    color: #808080808080;
}
.d-skills-bar .d-bar .d-info span {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 8px;
}
.progress-bar{
  overflow: unset;
}
.main-bar{
  position: relative;
  right: 0;
  top: -36px;
  color: #f0f0f0;
  font-size: 12px;
  font-weight: 700;
  background: #202020;
  padding: 1px 8px;
  border-radius: 3px
}

/* profile 8*/
.f-profile {
  margin-bottom: 0px;
  .fp-wrap {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    transition: .5s;
    outline: none;
    .fpw-overlay {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
    }
    .fpwo-wrap {
      width: 100%;
      position: absolute;
      bottom: 30px;
      text-align: center;
    }
    .fpw-overlay-btm {
      opacity: 0;
    }
    .img-fluid {
      max-width: 100%;
      height: auto;
      transition: .5s;
      outline: none;
    }
    &:hover{
      img {
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        filter: grayscale(100%);
        transform: scale(1.05);
      }
    }
  }
  h4 {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .fpwow-icons {
    display: inline-block;
    background: #ad8e6d;
    padding: 5px 10px 7px 10px;
    border-radius: 3px;
    a{
      padding: 0 5px;
      cursor: pointer;
      i{
        font-size: 15px;
        color: #808080;
      }
    }
  }
}

/* menu reveal */
#de-menu-reveal {
    float: right;
    width: 36px;
    height: 36px;
    padding: 6px;
    text-align: center;
    cursor: pointer;
    position: relative;
    z-index: 2;
    .lt,
    .lm,
    .lb{
        display:block;
        position:absolute;
        width:36px;
        height:2px;
        background:#808080808080;
        transition: .5s;
    }
    .lt{
    top:5px;
    }
    .lm{
        top:15px;
    }
    .lb{
        top:25px;
    }
    &.menu-open .lt{
        transition: .5s;
        top:15px;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    &.menu-open .lm{
        width:0;
        opacity:0;
        transition: .5s;
    }
    &.menu-open .lb{
        transition: .5s;
        top:15px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
.mainreveal{
  display: table;
  opacity: 0;
  transform: scale(0);
  background: #191A1C;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: .5s;
  .navbar-nav{
    display: table-cell !important;
    vertical-align: middle;
    li{
      display: block;
      text-align: center;
      margin: 10px 0;
      font-size: 20px;
       a {
        padding: 0;
        font-weight: 400;
      }
    }
  }
  &.show{
    opacity: 1;
    transform: scale(1);
    transition: .5s;
  }
}

video.pop {
  display: block;
  max-width: 100%;
  height: auto;
}

// /*** media all ***/
// @media (min-width: 1281px) {
//   .container,
//   .container-lg,
//   .container-md,
//   .container-sm,
//   .container-xl {
//     max-width: 1240px;
//     // max-width: 80%;
//   }
//   .float-text {
//     display: block;
//   }
//   .mobilemenu{
//     display: none;
//   }
//   .desktopmenu{
//     width: 70%;
//   }
// }
//
// @media only screen and (max-width: 1280px) {
//   .float-text {
//     display: none;
//   }
//   .desktopmenu{
//     width: 70%;
//   }
// }

// @media only screen and (max-width: 1199px){
//   .container {
//     padding-left: 30px;
//     padding-right: 30px;
//   }
//   .h1, h1.big, .h1_big, h1.ultra-big {
//     font-size: 32px;
//     line-height: 35px;
//   }
//   footer{
//     text-align: center;
//     .social-icons{
//       margin: 10px auto;
//       float: unset;
//     }
//   }
//   .CircularProgressbar{
//     width: 80% !important;
//   }
//   #hero-area .h1_big{
//     font-size: 150% !important;
//     line-height: normal !important;
//   }
// }
