@import "../style.scss";

#contact{
  min-height: calc(100vh - 269.2px - 81px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.course-img{
  display: flex;
  justify-content: flex-end;

  position: absolute;
  float: right;
  right: 0;
  top: 29%;
  img{
    max-width: 400px;
    min-width: 310px;
  }
  .spacer {
    width: calc((100vw - 1500px)/2);
    // max-width: 700px;
    min-width: 0px
  }
}
@media (max-width: 1450px) {
  .course-img{
    img{
      max-width: 400px;
      width: 25vw;
      min-width: 0px;
    }
  }
}



.cnct-title{
  width: 100%;
  padding: 40px 0 0;
}

.cnct-buttons{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 0 55px;
  width: 100%;

  .cnct-button{
    flex-direction: column;
    padding: 0 20px;
    p{
      text-align: center;
      font-family: $font-primary;
      color: $black!important;
      font-size: 14px;
    }
  }
  img{
    height: 90px;
    width: 90px;
    // transform: scale(0.95);

    filter: grayscale(100%); // helps make the images in the gallery gray
    &:hover{
      filter: grayscale(0); // helps make the images in the gallery gray
    }
  }
}

.cnct-email{
  justify-content: center;
  text-align: center;
  width: 100%;

  h4{
    font-weight: normal;
    font-size: 19px;
    margin: 0;
  }
  h6{
    font-family: $font-primary;
    font-size: 24px;
    font-weight: bolder;
    padding: 10px 0;
  }
}

.top-inputs{
  margin: 0 0 20px 0;
}
.text-input{
  width: 100%;
}


.cnct-resume{
  display: flex;
  justify-content: center;
  margin: 2%;

  Button{
    max-width: 250px;
    max-height: 200px;
    border-radius: 10px;
    background-color: $grey;
    p{
      margin: 0 0 3px 0;
      text-align: center;
      font-size: 35px;
      font-weight: 550;
      color: $background_light;
      font-family: $font-secondary;
    }
    &:hover{
      background-color: $grey_transparent;
      p{
        color: $grey;
      }
    }
  }
}



 // OG styles
.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #808080;
  background-color: #808080;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-border input[type="text"]{
  // padding: 8px;
  // margin-bottom: 20px;
  border: none;
  color: $background_dark;
  // border: solid 1px rgba(128, 128, 128, .2);
  // background: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  height: auto;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  &:focus{
    color: #808080;
    background: rgba(var(--primary-color-rgb), .1);
  }
}
.form-border textarea.form-control {
  min-height: calc(1.5em + (.75rem + 2px));
}
#contact_form textarea {
  height: 168px;
  color: #808080808080;
  border: solid 1px rgba(128, 128, 128, .2);
  background: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  &:focus{
    color: #808080;
    background: rgba(var(--primary-color-rgb), .1);
  }
}
.mt30 {
  margin-top: 30px;
}
.btn-main{
  display: inline-block;
  text-align: center;
  color: #808080;
  background: rgba(var(--primary-color-rgb), 1);
  outline: 0;
  text-decoration: none;
  padding: 4px 40px;
  font-size: 14px;
  border: none;
  font-weight: bold;
  transition: .5s;
  &:hover{
    transition: .5s;
    box-shadow: 2px 2px 20px 0px rgba(var(--primary-color-rgb), 0.5);
  }
}
#success, #failed{
  color: $secondary;
  margin-top: 30px;
  &.hide{
   display: none;
  }
  &.show{
    display: block;
  }
}

@media (max-width: 1280px) {
  .course-img{
    display:none;
  }
}

@media (max-width: 900px) {
  .cnct-buttons{
    img{
      height: 69px;
      width: 69px;
      filter: grayscale(0); // helps make the images in the gallery gray
    }
  } // contact-buttons
}

@media (max-width: 720px) {

  .cnct-email{
    padding: 10px 0;
    h4{
      font-weight: normal;
      font-size: 16px;
      margin: 0;
    }
    h6{
      font-family: $font-primary;
      font-size: 18px;
      font-weight: 600;
      padding: 10px 0;
    }
  }
  .cnct-resume{
    padding: 5px 0 25px;
  }
} // Media 900px
