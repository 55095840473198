@import "../style.scss";

.float-text {
  width: 20px;
  position: fixed;
  z-index: 1002;
  margin-left: 30px;
  text-align: center;
  letter-spacing: 2px;
  font-size: 12px;
  top: 50%;
  left: 15px;
  opacity: 1;
  transform: translate(-50%, -50%);

   span {
    color: $secondary!important;
    margin-left: -5px;
    margin-top: 20px;
    writing-mode: vertical-rl;
    letter-spacing: .75px;
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
    color: rgba(128, 128, 128, .5);
  }
  .de_social-icons{
    span.buton{
      position: relative;
      left: 1px;
      top: 1px;
      color: $secondary;
      cursor: pointer;
      margin-top: 0;
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
}

// .sticky{
//   position: fixed;
//   top: 0;
//   width: 100%;
// }
