@import "../style.scss";


footer {
  // width: 100vw;
  position: static;
  color: rgba(128, 128, 128, .8);
  padding: 20px 0;
  background: rgba(0, 0, 0, .1);
  border-top: solid 1px rgba(128, 128, 128, .1);
  .copy{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
  }
  .social-icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .cutie{
      display: flex;
      justify-content: flex-end;
      min-width: 110px;
      p{
        vertical-align: middle;
        display: inline-block;
        margin: 0px;
      }
    }
  }
  a {
    color: rgba(128, 128, 128, .5);
    text-decoration: none;
    &:hover{
      color: rgba(128, 128, 128, .5);
    }
    i {
      font-size: 16px !important;
      color: #808080808080;
      text-shadow: none;
      padding: 12px 10px 8px 10px;
      width: 34px;
      height: 34px;
      text-align: center;
      border-radius: 30px;
      -moz-border-radius: 30px;
      -webkit-border-radius: 30px;
      margin: 0 3px 0 3px;
      transition: .5s;
      &:hover {
        background: #808080;
        border-color: #eceff3;
        color: #333;
        transition: .5s;
      }
      &:before{
        position: relative;
        top: -2px;
      }
    }
  }
}

@media only screen and (max-width: 900px){
  footer{
    .row{
      flex-direction: column-reverse;
      align-items: center;
      .copy{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2% 0px;
      }
      .social-icons{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2% 0px;
      }
    }
  }
}
