@import "../style.scss";

#about{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 269.2px - 81px);
  // margin: 5vh 0;
}

.about-container{
  padding: 5% 0px;
  // max-width: calc(100% - 40vw);
  max-width: calc(715px);

  .about-portrait{
    height: 100%;
    margin: 0 0 0 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
      max-width: 70%;
      pointer-events: none;
    }
    .typed-title{
      margin: 25px 0!important;
      font-family: $font-secondary;
      .anim-title{
        font-size: 90px;
      }
    }
  }
  .about-desc{
    max-width: 100%;
    img{
      margin: 0 0 25px 0;
      max-width: 25%;
      min-width: 250px;
      pointer-events: none;
    }
    .anim-title{
      font-family: $font-secondary;
      font-weight: normal;
      font-size: 75px;
    }
    p{
      display: inline-block;
      width: 100%;
      margin: 0 0 5%;

      font-family: $font-primary;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5;
      text-align: justify;
    }
  } // about-desc

  .wip-photo{
    width: 100%;
    pointer-events: none;
  }
} // about-container



@media only screen and (max-width: 1280px){
  .about-container{
    // max-width: 50%;
    .about-portrait{
      img{
        max-width: 80%;
        pointer-events: none;
      }
    }
  }
}

@media only screen and (max-width: 1100px){
  .about-container{
    // max-width: 70%;
    .about-portrait{
      img{
        max-width: 100%;
        pointer-events: none;
      }
    }
  }
}

@media only screen and (max-width: 780px){
  .about-container{
    max-width: 85%;
  } // about-container
} // 780 media width

@media only screen and (max-width: 520px){
  .about-container{
    .about-portrait{
      .typed-title{
        margin: 25px 0!important;
        font-family: $font-secondary;
        .anim-title{
          font-size: 69px;
        }
      }
    }
  }
} // 520 media width
