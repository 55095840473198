@import "../style.scss";

.gallery{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 10vw;
}

.img-container {
  cursor: pointer;
  overflow: hidden;
  outline: none;
  // border-radius: 5px;
  img {
    transform: scale(1.05);
    transition: .5s;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  &:hover {
    z-index: 1;
    -webkit-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, .5);
    -moz-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, .5);
    box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, .5);
    transition: .5s;
    img {
      transform: scale(1);
      filter: grayscale(0);
      transition: .5s;
    }
  }
}

.big-gallery-modal{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $background_dark;
  img{
    pointer-events: none;
    max-height: 95vh;
    max-width: 75vw;
  }
  .icon-button-modal{
    img{
      height: 69px;
      width: 69px;
    }
  }
  #close-btn{
    margin: 2%;
    position: fixed;
    top: 0;
    right: 0;
  }
  #prev-btn{
    margin: 0 0 0 2%;
  }
  #next-btn{
    margin: 0 2% 0 0;
  }
}

.big-gallery-mobile{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: $background_dark;
  img#illustration{
    pointer-events: none;
    // margin: 125px 0 0;
    max-height: 75vh;
    max-width: 95vw;
  }
  .gallery-buttons{
    display: flex;
    justify-content: center;
  }
  .icon-button-modal{
    img{
      height: 100px;
      width: 100px;
    }
  }
  // #close-btn{
  //   margin: 2%;
  //   padding: 0;
  //   position: fixed;
  //   top: 0;
  //   right: 0;
  // }
  // #prev-btn{
  //   margin: 0 0 0 2%;
  // }
  // #next-btn{
  //   margin: 0 2% 0 0;
  // }
}

@media only screen and (max-width: 900px){
  .gallery{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 2.5vw;
  }
} // 900 media width

@media only screen and (max-width: 680px){
  .gallery{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 2.5vw;
  }
} // 680 media width
