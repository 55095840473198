@import "../style.scss";

.concept{
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  .galeries{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 5%;

    #backgrounds{
      width: 60%;
    }
    #charDesign{
      width: 80%;
    }

    .img-container img{
      pointer-events: none;
    }
  } // Galeries
} // Concept



.selector-buttons{
  padding: calc(4% - 31.8px) 0 1% 0;
  display: flex;
  justify-content: center;

  span{
    font-size: 25px
  }
  button{
    border: 2px solid $light_grey;
    // border: none;
    // &#backgroundButton{
    //   border-left-style: none;
    //   border-bottom-style: none;
    // }
    // &#charDesignButton{
    //   border-top-style: none;
    //   border-right-style: none;
    // }
    border-radius: 15px;
    background: transparent;
    font-size: 20px;
    margin: 0 10px;
    &:hover{
      // background: $grey;
      color: $grey;
    }
    &.focused{
      // color: $light_grey;
      font-weight: bold;
      background: transparent;
      border-color: transparent;
      border-bottom-color: $grey;
      // border-style: none;
      border-left-style: none;
      border-right-style: none;
    }
    // &:focus{
    //
    //   font-weight: bold;
    //   background: transparent;
    //   border-color: transparent;
    //   border-bottom-color: $grey;
    //   // border-style: none;
    //   border-left-style: none;
    //   border-right-style: none;
    //
    //
    //   // background: $light_grey;
    //   // border-color: $grey;
    //   // // border-style: none;
    //   // border-bottom-style: solid;
    //   // font-weight: bold
    // }
    &:active{
      background: $medium_grey;
      color: $grey;
      border-color: $grey;
      border-style: solid;
      border-left-style: none;
      border-right-style: none;
    }
  }
}


@media only screen and (max-width: 1080px){
  .selector-buttons{
    display: flex;
    justify-content: center;

    padding: 3% 0 1%;
    button{
      margin: 2% 0;
      &.active{
        background: $pink;
      }
    }
  }
  .concept{
    .galeries{
      #backgrounds{
        width: 80%;
      }
      #charDesign{
        width: 80%;
      }
    }
  }
} // 900 media width

@media only screen and (max-width: 900px){
  .selector-buttons{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 3% 0 5%;
    button{
      margin: 2% 0;
      &.focused{
        background: transparent;
        color: $grey;
      }
    }
  }
  .concept{
    .galeries{
      #backgrounds{
        width: 95%;
      }
      #charDesign{
        width: 95%;
      }
    }
  }
} // 900 media width

@media only screen and (max-width: 680px){
} // 680 media width
