@import "../style.scss";


.banner{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // width: 100vw;
  background: $background_light;

  .navbarHolder{
    height: 123.8px;
    width: 100vw;
    display: flex;
    align-items: center;
  }

  img{
    width: 80%;
    max-width: 650px;
  }
}


// .icon-text{
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }
.icon{
  display: flex;
  flex-direction: column;
  align-items: center;
  img{
    width: 75px;
    height: 75px;
  }
}

.navbarContainer{
  margin: 0px;
  display: center!important;
  justify-content: center!important;
  width: 60%;
  min-height: 50px;

  .desktopmenu{
    // width: 85%;
  }
}

.burgermenu{
  display: none;
}

#header-wrap {
  width: 100%;
  z-index: 1000;
  transition: top 2s ease 0s;
  height: 100%;

  nav.navbar{
    justify-content: center;
    font-family: var(--title-font);
    font-size: 22px;
    font-weight: 700;
    padding: 0;
    // height: 100%;


    &.rev{
      padding: 20px 0;
    }
  }
  &.transparent {
    background: none;
  }
  &.sticky{
    height: auto;

    top: 0;
    width: 100vw;
    position: fixed;
    nav.navbar{
      background: $background_transparent;
      // background: none;
    }
  }
}
.navbar-nav{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .nav-item{
    display: inline-block;
    margin: 0 10px;
    a{
      text-align: center;
      text-decoration: none;
      padding: 10px 15px;
      color: $grey;
      cursor: pointer;
      &.active{
        p{
          color: $secondary;
          font-weight: 700;
          font-family: $font-primary;
          // text-transform: uppercase;
        }
      }
      p{
        margin: 0px;
        font-weight: 500;
        font-family: $font-primary;
        // text-transform: uppercase;
      }
    }
  }
}

@media only screen and (max-width: 900px){

  .banner{
    .navbarHolder{
      height: auto;
      // min-height: 99.6px;
      min-height: 7.6vh;
      width: 60vw;
      display: flex;
      align-items: stretch;
    }
  }

  .burgermenu{
    z-index: 6;
    display: block;
    width: auto;
    height: auto;
    padding: 0;
    border: none;
    background: none;
    color: $secondary;
    // padding: 15px;
    img{
      height: 100%;
      // width: 80%;
    }
  }
  .navbar-brand{
    z-index: 1000;
  }
  .desktopmenu{
    display: none;
  }
  .navbarContainer{
    display: flex;
    align-items: center;
    height: 100%;

  }
  .bannerImg-scroll{
    // margin: 0px 20% 124px!important;
    margin-bottom: 90px;
  }

  .mobilemenu{
    position: absolute;
    top: 0;
    width: 100vw;
    background: $transparent_grey;
    padding: 20% 20px 0px;
    display: flex;
    flex-direction: column;
    z-index: 5;

    .navbar-nav .nav-item{
      display: block !important;
      border-bottom: 1px solid rgba(128, 128, 128, 0.1);
      margin: 0;
      padding: 0px 0px;
      width: 100%;
    }
  }
  .icon{
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
      width: 50px;
      height: 50px;
    }
    p{
      font-size: 1.2rem;

    }
  }

  .bannerImg-scroll{
    // padding-bottom: 90px;
  }


  #header-wrap nav.navbar .navbar-nav .nav-item a{
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
    border-bottom-color: rgba(128, 128, 128, .1);
    font-size: 36px;
  }

  #header-wrap {
    // box-sizing: initial!important;
    &.sticky{
      top: 0;
      width: 60vw;
      position: fixed;
      nav.navbar{
        background: none;
      }
    }
  }
  .bloglist.item{
    margin-right: 0px;
    padding: 5px;
  }
}

@media only screen and (max-width: 620px){
  .banner{
    .navbarHolder{
      min-height: 7vh;
    }
  }
}

@media only screen and (max-width: 540px){
  .banner{
    .navbarHolder{
      min-height: 6vh;
    }
  }
  .icon{
    img{
      width: 40px;
      height: 40px;
    }
    p{
      font-size: 1rem;
    }
  }
}

@media only screen and (max-width: 440px){
  .banner{
    .navbarHolder{
      min-height: 5vh;
    }
  }
}
