@import "../style.scss";

#Books{
  min-height: calc(100vh - 269.2px - 81px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.book-gallery{
  margin: calc(5% - 20px) 0;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .img-list{
    overflow: hidden;
    justify-items: center;
    // padding: 5vh 0!important;
    margin: 0;
    .book-item{
      padding: 30px 0;

      .book-cover{
        cursor: pointer;

        // border-radius: 5px;
        img{
          // border-radius: 5px;
          width: 100%;
          height: auto;
          object-fit: cover;
          transform: scale(1.05);
          transition: .5s;
          background-color: transparent;
        }
        &:hover {
          z-index: 1;
          -webkit-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, .5);
          -moz-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, .5);
          box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, .5);
          img{
            transform: scale(1);
            transition: .5s;
          }
        }
      }
      .book-info{
        font-family: $font-primary;
        h3.book-title{
          margin: 5% 0 0;
          padding: 15px 0px 5px;
          text-align: center;
        }
        h4.book-desc{
          font-size: 18px;
          font-weight: normal;
          text-align: center;
        }
      }
    }
  }
}




.book-modal{
  // height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .book-container{
    display: flex;
    flex-direction: row-reverse;
    // justify-content: space-evenly;
    align-items: center;

    background-color: $transparent_grey;
    border: none;
    border-radius: 15px;
    width: 90vw;
    height: 95%;


    .book-image-list-container{
      height: 95%;
      .book-image-list {
        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-color: $white $light_grey;
        height: 100%;
        margin: 0 0 0 1.5%;
        .book-list-item{
          align-items: center;
          img{
            pointer-events: none;
            height: auto;
            width: 100%;
          }
        }
      }
    }

    .book-info{
      min-width: 40%;
      max-width: 50%;
      display: flex;
      flex-direction: column;

      h3.book-title{
        font-size: 35px;
        margin: 5% 0 0;
        padding: 15px;
      }
      h5.book-desc{
        font-size: 20px;
        font-weight: normal;
        padding: 15px;
      }
      p{
        font-size: 16px;
        padding: 15px 15px 5px;
      }
    }
    .icon-button-close{
      margin: 2% 5%;
      position: fixed;
      top: 0;
      right: 0;
      img{
        border-radius: 33px;
        background-color: $transparent_black;
        height: 69px;
        width: 69px;
      }
    } // Icon Button Modal
  } // book contianer

}


@media (max-width: 1280px) {
  .book-gallery{
    margin: 0 auto;
    width: 95%;
  }
}

@media (max-width: 900px) {
  // .book-gallery{
  //   margin: 0 auto;
  //   width: 75%;
  // }
  .book-modal{
    position: relative;
    height: 95vh;
    .book-container{
      display: flex;
      flex-direction: column;

      margin: 25px 0 0 0;
      min-height: 100%;
      overflow-y: scroll;
      scroll-behavior: smooth;
      scrollbar-width: thin;
      scrollbar-color: $white $light_grey;

      .book-image-list-container{
        display: flex;
        flex-direction: column-reverse;
        height: auto;

        padding: 25px 0;

        .book-image-list{
          overflow: hidden;
          height: 100%;
          width: 95%;
          margin: 0 2%;
        }
      } // image list container
      .book-info{
        min-width: 95%;
        h3.book-title{
          margin: 10% 0 0;
          font-size: 28px;
          padding: 15px 0px 5px 20px;
          // text-align: center;
        }
        h5.book-desc{
          margin: 0;
          font-size: 20px;
          font-weight: 550;
          padding: 0px 0px 0px 25px;
          // text-align: center;
        }
        p{
          font-size: 16px;
          padding: 0px 10px;
          // max-width: 50%;
        }
      } // Book info
    } // book-container

    .icon-button-close{
      img{
        margin: 25px 0 0 0;
        position: fixed;
        z-index: 1;
        top: 0;
      }
    }
  } // book modal
}

@media (max-width: 900px) {
  .icon-button-close{
    margin: auto!important;
    position: relative!important;
    top: auto!important;
    right: auto!important;
    img{
      height: 50px!important;
      width: 50px!important;
    }
  } // Icon Button close
}
